<template>
    <div></div>
</template>

<script>
export default {
    name: 'thirdLogistics',
    data() {
        return {}
    },
    created() {
        this.openLoading()
    },
    mounted() {
        // 選完超商導向回來後如果有值
        if (this.$route.query.sid && this.$route.query.sname && this.$route.query.logistics) {
            this.pastValToParentWindow()
        } else {
            this.getConvenienceData()
        }
    },
    methods: {
        // 回傳值回去
        pastValToParentWindow() {
            let payload = {
                sname: this.$route.query.sname,
                sid: this.$route.query.sid,
                logistics: this.$route.query.logistics,
            }

            window.opener.postMessage({
                convenienceData: {
                    payload,
                },
            })

            window.close()
        },
        // 第三方選取超商頁面
        getConvenienceData() {
            const kcval = this.$aes.encrypt(
                this.$store.state.login.userInfo.enval1,
                this.$store.state.login.userInfo.enval2,
                this.$store.state.login.userInfo.stoken
            )

            let link = `${process.env.VUE_APP_BASE_URL}/front/store/morder/getLogisticsSid.php`
            link += `?sn=${process.env.VUE_APP_SN}`
            link += `&lthird=${this.$route.query.lthird}`
            link += `&logistics=${this.$route.query.logistics}`
            link += `&rturl=${process.env.VUE_APP_DOMAIN}`

            location.href = link
        },
        openLoading() {
            const loading = this.$vs.loading({
                color: 'success',
            })
        },
    },
}
</script>
